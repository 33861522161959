import React from "react"
import styled from "styled-components";

import Layout from "../components/layout"
import Header from "../components/header"
import SEO from "../components/seo"

const Flex = styled.div `
  display: flex;
  flex-wrap: wrap;
`;
const Wrapper = styled.div `
  max-width: 70ch;
`;
const Signing = styled.div `
  border-top: 1px solid #ccc;
  padding-top: 40px;
  margin-top: 40px;
`;
const Button = styled.a `
  padding: 7px 10px 7px 10px !important;line-height: 31px !important;height:45px !important;min-width:200px !important;text-decoration: none !important;display:inline-flex !important;color:#ffffff !important;background-color:#668fd2 !important;border-radius: 5px !important;border: 1px solid transparent !important;padding: 7px 10px 7px 10px !important;font-size: 20px !important;letter-spacing:-0.08px !important;box-shadow: 0px 1px 2px rgba(190, 190, 190, 0.5) !important;-webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;margin: 0 auto !important;font-family:'Lato', sans-serif !important;-webkit-box-sizing: border-box !important;box-sizing: border-box !important;-o-transition: 0.3s all linear !important;-webkit-transition: 0.3s all linear !important;-moz-transition: 0.3s all linear !important;-ms-transition: 0.3s all linear !important;transition: 0.3s all linear !important;
  img { height: 30px !important;width: 31px !important;margin-bottom: 1px !important;box-shadow: none !important;border: none !important;vertical-align: middle !important; }
  span { margin-left:8px;font-size:19px !important; }
  &:hover, &:active, &:focus { -webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;text-decoration: none !important;box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;opacity: 0.85 !important;color:#ffffff !important; }
`;

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="About" />
      <Header /> 
      <h2>About</h2>
      <Flex>
        <Wrapper>
          <p>beluga is a project by <a href="https://rachelbinx.com/">rachel binx</a>, who wanted a way to build out weird art ecommerce projects. Custom software (& self-hosting of the site) allows greater design flexibility and lower monthly fees than using a typical ecommerce platform.</p>

          <p>rachel's body of work combines data visualization with one-off manufacturing, via co-creation interfaces for customers to turn their data into a custom object. Projects of hers include <a href="https://rachelbinx.com/project/meshu">Meshu</a>, <a href="https://rachelbinx.com/project/gifpop">Gifpop</a>, <a href="https://rachelbinx.com/project/monochome">Monochōme</a>, <a href="https://rachelbinx.com/project/cliffs-coasts">Cliffs&Coasts</a>, <a href="https://rachelbinx.com/project/manymaps">ManyMaps</a>, and <a href="https://rachelbinx.com/project/gpx-jewelry">GPX Jewelry</a>. Some of those were built on top of e-commerce platforms, and some were fully custom software.</p>

          <p>beluga is released as open-source software so that anyone can build out their own storefront. It is ideal for creative coders who have products that they want to sell (either digital or physical), and want full control over the look and feel of their store.</p>

          <p>The name "beluga" is a riff on the original codename for this repo. beluga is the third time that she has built an ecommerce site from scratch — this is her "white whale." <span role="img" aria-label="beluga">🐋</span></p>

          <Signing>
            <p>If you wind up using this software for a store, please get in touch! <span style={{ display: "inline-block" }}>(rachelbinx @ gmail)</span> <br/> I'd love to see what you make with it :)</p>
            <p>- rachel</p>
          </Signing>
        </Wrapper>
        <Button target="_blank" href="https://www.buymeacoffee.com/binx">
          <img src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg" alt="Buy me a coffee" />
          <span>buy me a coffee :)</span>
        </Button>
      </Flex>
    </Layout>
  )
}

export default IndexPage